
// 公共请求方法
// import base from './base'
import axios from '../utils/request';


// const api = {
//     get() {
//     return axios.get('https://ppt.yantootech.com/api/', {} )
//         .then(data => {
//             return data
//         })
//     },
//     post() {
//     return axios.get('/api/', {} )
//         .then(data => {
//             return data
//         })
//     },

// }

// export default api

//获取验证码
export const getVerifyCode = (params) => {
    let str = params.userId ? ('?la_xin_user_id=' + params.userId) : ''
    return axios.post('/api/getVerifyCode' + str, {
        mobile: params.shoujihao
    })
}

//根据验证码登录
export const loginByVerifyCode
    = (params) => {
        return axios.post('/api/loginByVerifyCode', {
            mobile: params.shoujihao,
            code: params.code
        })
    }
//绑定手机号
export const updateUserInfo
    = (params) => {
        return axios.post('/api/user/updateUserInfo', {
            ...params
        })
    }

//根据token登录
export const getLoginToken = (a) => {
    let str = a ? ('?la_xin_user_id=' + a) : ''
    return axios.get('/api/getLoginQr' + str, {

    })

}


// export const wxLogin = (url) => {
//     return axios.post(url, {})
// }

//获取token的登录状态
export const checkTokenStatus = (params) => {
    return axios.post('/api/checkTokenStatus', {
        token: params.token,
    })
}
//获取用户信息
export const getUserInfo = (params) => {
    return axios.get('/api/user/getUserInfo')
}
//获取任务状态
export const getTodayTask = (params) => {
    return axios.get('/api/task/getTodayTask')
}
//领取积分
export const receiveTaskScore = (id) => {
    return axios.post('/api/task/receiveTaskScore', { id })
}
//退出登录
export const logout = (id) => {
    return axios.post('/api/logout')
}
// PPT重命名
export const removePptName = (params) => {
    return axios.post('/api/ppt/removePptName', {
        id: params.id,
        name: params.name
    })
}
// PPT移入回收站
export const movePptToRecycleBin = (params) => {
    return axios.post('/api/ppt/movePptToRecycleBin', {
        ids: params.id
    })
}
export const mmToJson = (params) => {
    return axios.post('/api/ppt/mmToJson', {
        info: params
    })
}
//导入文件

//生成ppt的大纲接口
export const createPpt = (params, cjPPT) => {
    return axios.post('/api/ppt/createPptNew', {
        name: params.name,
        ppt_demo_id: params.ppt_demo_id,
        attr_json: params.attr_json,
        type: params.type,
        outline: params.outline,
        // data:params
    }, cjPPT)
}
// 获取PPT模板列表
export const PPTlist = () => {
    return axios.get('/api/ppt/getPptDemoList')
}
// 获取PPT列表
export const getPptList = () => {
    return axios.get('/api/ppt/getPptList?limit=9999999999')
}
// 获取PPT列表(回收站)
export const deletePptList = () => {
    return axios.get('/api/ppt/getPptList?is_delete=1&&limit=9999999999')
}
// 获取ppt详情
export const getPptDetail = (params) => {
    return axios.get('/api/ppt/getPptDetail', {

        id: params.id

    });
}

// 获取最近查看
export const visitgetPptList = () => {
    return axios.get('/api/ppt/getPptList?is_visit=1&&limit=999999999999')
}
// 移出回收站(批量)
export const restorePptFromRecycleBin = (params) => {
    return axios.post('/api/ppt/restorePptFromRecycleBin', {
        // ids: params
        ids: params.ids
    })
}
// 彻底删除ppt（批量）
export const deletePpt = (params) => {
    return axios.post('/api/ppt/deletePpt', {
        ids: params.ids
    })

}
// 根据页码获取其他风格ppt
export const getOtherStylePptPage = (params) => {
    return axios.get('/api/ppt/getOtherStylePptPage', {
        id: params.id,
        page: params.page,
    })
}

//查询大纲模板
export const getPptTypeList = (params) => {
    return axios.get('/api/ppt/getPptTypeList?id=1')
}

//打印大纲

export const chatAPIProcess = (params, onDownloadProgress) => {
    return axios.post('/api/ppt/getOutLineByTitle', {
        title: params.title,
        name: params.title,
        attr_json: params.attr_json
    }, onDownloadProgress)
}

//大纲的json
export const chatAPIJson = (params) => {
    return axios.post('/ai/pptJson', {
        conversation_id: params.conversation_id
    })
}
//
export const pptContent = (params) => {
    return axios.post('/ai/pptContent', params)
}
//
export const collectUrl = (params) => {
    return axios.post('/api/user/collectUrl')
}

export const getBindWxQr = (params) => {
    return axios.get('/api/user/getBindWxQr')
}
export const getPptDemoDescColor = (params) => {
    return axios.get('/api/ppt/getPptDemoDescColor')
}


