import {
  createRouter,
  createWebHashHistory
} from 'vue-router'
import HomeView from '../views/HomeView.vue'

// 定义路由
const routes = [
  {
    // 主页路由
    // 首页
    path: '/login',
    name: 'login',
    component: function () {
      return import('@/views/login.vue')
    },
  },
  // //登录
  // {
  //   path: '/login',
  //   name: 'login',
  //   component: () => import('@/views/login.vue')
  // },
  // 会员充值页
  {
    path: '/member',
    name: 'Member',
    component: function () {
      return import('../views/member/member.vue')
    }
  },
  // 支付页
  {
    path: '/payment',
    name: 'Payment',
    component: function () {
      return import('../views/payment/payment.vue')
    }
  },
  // 登录成功的全部演示页
  {
    path: '/',
    name: 'HelloWor',
    component: function () {
      return import('../views/index.vue')
    },
    redirect: '/demon', //默认跳转
    children: [{//全部演示
      path: '/demon',
      name: 'Demo',
      component: function () {
        return import('../views/demonstration/demonstration.vue')
      },

    }, {//垃圾箱
      path: '/recycle',
      name: 'Recycle',
      component: function () {
        return import('../views/recycle-bin/recycle-bin.vue')
      },

    }]
  },
  {
    // Ai问话-生成ppt页
    path: '/home',
    name: 'Home',
    component: HomeView
  },
  {
    // 选择预览模板页
    path: '/templ',
    name: 'Templ',
    component: function () {
      return import('../views/template/template.vue')
    }
  },
  {
    // 选择模板页
    path: '/preview',
    name: 'Preview',
    component: function () {
      return import('../views/preview/preview.vue')
    }
  },
  {
    // 生成动画页
    path: '/cartoon',
    name: 'Cartoon',
    component: function () {
      return import('../views/cartoon/cartoon.vue')
    }
  },

  {
    // PPT编辑页
    path: '/about',
    name: 'About',
    // 路由级别的代码分割
    // 这将为该路由生成一个单独的块（about.[hash].js），
    // 在访问该路由时进行延迟加载。
    component: function () {
      return import( /* webpackChunkName: "about" */ '../views/AboutView/AboutView.vue')
    }
  }
]

// 创建路由器
const router = createRouter({
  // 创建一个基于 Web 历史记录的路由器
  history: createWebHashHistory(process.env.BASE_URL),
  routes
})
// router.beforeEach((to, form, next) => {
//   const token = localStorage.getItem('token')
//   // 判断本地是否有token
//   if (token) {
//     next();
//   } else {
//     router.push("/");
//   }
//   // 如果没有token 则跳转到登录页面

// });


// 导出路由器
export default router