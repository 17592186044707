import { createStore } from 'vuex'

// 创建并返回一个 Vuex 商店对象
export default createStore({
  state: {
    // Vuex 商店的状态对象，用于存储整个应用的状态
  },
  getters: {
    // Vuex 商店的获取器对象，用于从状态中计算出一部分相关的值
  },
  mutations: {
    // Vuex 商店的变更对象，用于描述状态更新的函数集合
  },
  actions: {
    // Vuex 商店的动作对象，用于描述异步操作的函数集合
  },
  modules: {
    // Vuex 商店的模块对象，用于包含其他 Vuex 商店
  }
})