import axios from 'axios';
import router from "@/router"
// 创建一个axios实例
const instance = axios.create({
  baseURL: 'https://ppt.yantootech.com', // 设置请求的基础URL
  timeout: 300000, // 请求超时时间
  headers: {
    "Content-Type": "application/json;"
  }
});

// request拦截器
instance.interceptors.request.use(config => {
  const token = localStorage.getItem('token')
  // 如果 token 存在
  // 让每个请求携带自定义 token 请根据实际情况自行修改
  if (token) {
    config.headers['Authorization'] = 'Bearer ' + token
  } else {
    router.push('/login')
  }
  return config
})
// 添加响应拦截器
axios.interceptors.response.use(function (response) {
  // 2xx 范围内的状态码都会触发该函数。
  // 对响应数据做点什么
  return response;
}, function (error) {
  if (response.request.status == 401) {
    router.push('/login')
  }
  // 超出 2xx 范围的状态码都会触发该函数。
  // 对响应错误做点什么
  return Promise.reject(error);
});

// 封装GET请求
export function get(url, params, onDownloadProgress) {
  console.log('开始', url)
  return instance.get(url, {
    params: params,
    onDownloadProgress
  })
    .then(res => {
      console.log('请求一个', res.data.data)
      return res.data;
    })
    .catch(err => {
      console.log('请求一个err', err.response.request.status)
      if (err.response.request.status == 401) {
        localStorage.removeItem('token')
        router.push('/login')
      }
      console.error(err);
    });
}

// 封装POST请求
export function post(url, params, onDownloadProgress) {
  console.log('开始', url)
  return instance.post(url, params, { onDownloadProgress })
    .then(res => {
      console.log('请求一个', res.data.data)
      return res.data;
    })
    .catch(err => {
      console.log('请求一个err', err.response.request.status)
      if (err.response.request.status == 401) {
        localStorage.removeItem('token')
        router.push('/login')
      }
      console.error(err);
    });
}

// 封装其他HTTP请求方法如PUT、DELETE等也是类似的

export default {
  get,
  post,
};
