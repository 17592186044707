<template>
  <div class="home">
    <img
      style="cursor: pointer; position: absolute; left: 6.25rem; top: 2.25rem"
      @click="fanhui"
      src="https://yantoo-ailab-1320473685.cos.ap-shanghai.myqcloud.com/uploads/返回@2x-ppt.png"
      alt=""
      class="fanhui"
    />
    <div class="neirong">
      <ul class="list">
        <!-- 第一步 -->
        <li class="item" v-if="cPpt_s > 0">
          <div :class="{ ais: true, yuan1: guodu.item.ais == 1 }">
            <p class="text">{{ textObj.item }}</p>
          </div>
          <div :class="{ xuanze: true, yuan1: guodu.item.xuanze }">
            <div
              @click="sc1(1)"
              :class="{
                img: true,
                img1: true,
                imged1: cPpt.type == 'ai' || item_imged == 1,
              }"
              @mouseenter="item_imged = 1"
              @mouseleave="item_imged = 0"
            >
              <div class="imgs">
                <img
                  src="https://yantoo-ailab-1320473685.cos.ap-shanghai.myqcloud.com/uploads/AI智能生成-xPPT.png"
                  alt=""
                />
              </div>
              <div class="button button1">
                <p>AI智能生成</p>
              </div>
            </div>
            <div
              @click="sc1(0)"
              :class="{
                img: true,
                img2: true,
                imged2: cPpt.type == 'demo' || item_imged == 2,
              }"
              @mouseenter="item_imged = 2"
              @mouseleave="item_imged = 0"
            >
              <div class="imgs">
                <img
                  src="https://yantoo-ailab-1320473685.cos.ap-shanghai.myqcloud.com/uploads/导入本地大纲-xPPT.png"
                  alt=""
                />
              </div>
              <div class="button button2">
                <p>导入本地大纲</p>
              </div>
            </div>
          </div>
        </li>
        <!-- 第二步 -->
        <li class="item1" v-if="cPpt_s > 1">
          <div
            v-if="cPpt.type == 'ai'"
            :class="{ ais: true, yuan1: guodu.item1.ais == 1 }"
          >
            <p class="text">{{ textObj.item1 }}</p>
          </div>
          <div :class="{ xuanze: true, yuan1: guodu.item1.xuanze }">
            <!-- <template v-if="cPpt.type == 'demo'">
              <div class="leixingAi" @click="sc2(index)" @mouseenter="item1_imged = index"
                @mouseleave="item1_imged = -1" v-for="(item, index) in leixings" :key="'leixing' + index">
                <el-upload class="avatar-uploader" :accept="index == 0 ? '.mm' : '.md'"
                  :action="index == 0 ? 'https://ppt.yantootech.com/api/ppt/mmToJson' : 'https://ppt.yantootech.com/api/ppt/mdToJson'"
                  :show-file-list="false" :on-success="handleAvatarSuccess" name="info"
                  :before-upload="beforeAvatarUpload" :headers="{ 'Authorization': 'Bearer ' + token }">
                  <div :class="{ img: true, imged: isimged == index || item1_imged == index }">
                    <img :src=item.image alt="">
                  </div>
                </el-upload>
                <p>
                  {{ item.name }}
                </p>
              </div>
            </template> -->
            <template v-if="cPpt.type == 'demo'">
              <div class="box">
                <p class="text-tishi">
                  请在此处粘贴您的markdown大纲，参考格式：
                </p>
                <hr />
                <textarea
                  class="shuru"
                  v-model="strMarkdown"
                  :placeholder="str999"
                >
                </textarea>
              </div>
              <div
                class="xiayibu-box"
                @click="nextMd"
                v-if="cPpt.type == 'demo'"
              >
                <div class="xiayibu">
                  <img
                    style="
                      width: 11px;
                      height: 11px;
                      position: relative;
                      top: 16px;
                      left: calc(50% + 36px);
                    "
                    src="https://yantoo-ailab-1320473685.cos.ap-shanghai.myqcloud.com/uploads/下一页-h.png"
                    alt=""
                  />
                  <img
                    style="
                      position: absolute;
                      left: 263px;
                      top: 16px;
                      width: 71px;
                    "
                    src="https://yantoo-ailab-1320473685.cos.ap-shanghai.myqcloud.com/uploads/下一步-xPPT.png"
                    alt=""
                  />
                </div>
              </div>
            </template>
            <template v-if="cPpt.type == 'ai'">
              <div
                class="leixingAi"
                @click="sc2(index)"
                @mouseenter="item1_imged = index"
                @mouseleave="item1_imged = -1"
                v-for="(item, index) in leixings"
                :key="'leixing' + index"
              >
                <div
                  class="kuang"
                  :style="{
                    transform: 'translateX(' + isimged * 200 + 'px)',
                    opacity: isimged != -1 ? 1 : 0,
                  }"
                  v-if="index == 0"
                ></div>
                <div
                  class="dian"
                  :style="{
                    transform: 'translateX(' + isimged * 200 + 'px)',
                    opacity: isimged != -1 ? 1 : 0,
                  }"
                  v-if="index == 0"
                ></div>
                <div
                  class="dian2"
                  :style="{
                    transform: 'translateX(' + isimged * 200 + 'px)',
                    opacity: isimged != -1 ? 1 : 0,
                  }"
                  v-if="index == 0"
                ></div>
                <div
                  class="dian3"
                  :style="{
                    transform: 'translateX(' + isimged * 200 + 'px)',
                    opacity: isimged != -1 ? 1 : 0,
                  }"
                  v-if="index == 0"
                ></div>
                <div
                  :class="{
                    img: true,
                    imged: isimged == index || item1_imged == index,
                  }"
                >
                  <img
                    :src="
                      isimged == index || item1_imged == index
                        ? item.image[1]
                        : item.image[0]
                    "
                    alt=""
                  />
                  <img
                    v-if="isimged == index"
                    style="
                      position: absolute;
                      top: 100%;
                      left: 50%;
                      transform: translateX(-50%) translateY(-50%);
                      width: 26px;
                      height: 26px;
                    "
                    src="https://yantoo-ailab-1320473685.cos.ap-shanghai.myqcloud.com/uploads/选中-xPPT.png"
                    alt=""
                  />
                </div>
                <p
                  :style="
                    isimged == index || item1_imged == index
                      ? 'color: #000;font-weight: 700;'
                      : 'color: #4C4C4C;'
                  "
                >
                  {{ item.name }}
                </p>
              </div>
            </template>
          </div>
        </li>
        <!-- 第三步 -->
        <li class="item2" v-if="cPpt_s > 2 && cPpt.type == 'ai'">
          <div :class="{ ais: true, yuan1: guodu.item2.ais == 1 }">
            <p class="text">
              {{ textObj.item2 }}
            </p>
          </div>
          <div :class="{ bbox: true, yuan1: guodu.item2.bbox }">
            <div class="input">
              <span style="line-height: 60px; width: 72px; text-align: right"
                >标题 ：
              </span>
              <input
                @keyup.enter="sc4(cPpt.name)"
                class="inputss"
                style="width: 741px; height: 60px; font-size: 14px"
                v-model="cPpt.name"
                :placeholder="'试试 xxx' + leixings[isimged].name"
              />
            </div>
            <p class="p">
              您可以直接生成， 也可以补充下方内容，生成PPT内容将会更加丰富
            </p>
            <div
              :class="{
                input2: true,
                zuihou: leixings[isimged].key_word_map.length == index + 1,
              }"
              v-for="(item, index) in leixings[isimged].key_word_map"
              :key="'xuqiu' + index"
            >
              <span>{{ item.name }} ：</span>
              <div class="textarea-box" v-if="!item.arr">
                <textarea
                  class="canshu"
                  :class="{ textareaed: item.word?.length > 45 }"
                  v-model="item.value"
                  :placeholder="item.word"
                ></textarea>
              </div>

              <span class="sp" v-if="item.arr">
                <p
                  :class="{ p1: itts.ised }"
                  v-for="(itts, ittx) in item.arr"
                  @click="sc3(itts)"
                  :key="'key_word_map' + ittx"
                >
                  {{ itts.name }}
                </p>
              </span>
            </div>
            <div
              class="shengceng"
              style="
                width: 252px;
                height: 50px;
                position: relative;
                left: calc(50% - 126px);
                background-color: #7fa3ee;
                border-radius: 25px;
                text-align: center;
              "
            >
              <p
                style="
                  line-height: 50px;
                  cursor: pointer;
                  font-size: 18px;
                  color: #ffffff;
                  font-weight: 700;
                  font-style: italic;
                "
                @click="sc4(cPpt.name)"
              >
                生成大纲
              </p>
            </div>
          </div>
          <!-- 撑高度的盒子 -->
          <div
            class="chengaodu"
            v-if="isimged != 3"
            :style="{
              height: isimged == 0 ? '24px' : isimged != 0 ? '88px' : '',
            }"
          ></div>
        </li>
        <!-- 第四步 -->
        <li class="item3" v-show="cPpt_s == -1">
          <div :class="{ ais: true, yuan1: guodu.item3.ais == 1 }">
            <p class="text" v-if="intext == 1">{{ textObj.item3 }}</p>
            <div class="text" v-if="intext == 0">
              <!-- <div class="spbox"></div>
              <div class="spbox"></div>
              <div class="spbox"></div> -->
              <img
                class="qipao"
                src="https://yantoo-ailab-1320473685.cos.ap-shanghai.myqcloud.com/uploads/气泡1.png"
                alt=""
              />
              <img
                class="qipao"
                src="https://yantoo-ailab-1320473685.cos.ap-shanghai.myqcloud.com/uploads/气泡2.png"
                alt=""
              />
              <img
                class="qipao"
                src="https://yantoo-ailab-1320473685.cos.ap-shanghai.myqcloud.com/uploads/气泡3.png"
                alt=""
              />
            </div>
          </div>
          <div :class="{ bbox: true, yuan1: guodu.item3.bbox }">
            <!-- <div class="box1">
              <img
                @click="siweidaotuFun"
                src="https://yantoo-ailab-1320473685.cos.ap-shanghai.myqcloud.com/uploads/1-xPPT.png"
                alt=""
              />
            </div>
            <div class="box2">
              <img
                src="https://yantoo-ailab-1320473685.cos.ap-shanghai.myqcloud.com/uploads/2-xPPT.png"
                alt=""
              />
            </div> -->
            <p style="position: relative">
              您可以在下方随意编辑大纲内容
              <!-- <img
                class="wenhao"
                src="https://yantoo-ailab-1320473685.cos.ap-shanghai.myqcloud.com/uploads/ppt？.png"
                alt=""
              /> -->
            </p>
            <div
              style="
                background-color: #d3ddff;
                height: 1px;
                width: 100%;
                margin: 18px 0;
              "
            ></div>
            <ul class="xxbox" v-show="text == 1">
              <div class="zxian" :style="zlong"></div>
              <li
                v-for="(item, index) in template"
                :style="
                  index + 1 == template.length ? 'margin-bottom:3.2px;' : ''
                "
                :key="index + 'xxbox'"
              >
                <p v-if="item.lv == 1 || item.lv == 3">{{ item.name }}</p>
                <!-- 点 -->
                <div class="dian" v-if="item.lv == 1"></div>
                <!--多级点 -->
                <div
                  :class="{
                    dian2: true,
                    dian2x1: item.lv == 3,
                    dian2x2: item.lv == 4,
                  }"
                  v-if="item.lv > 1"
                ></div>
                <!-- 线-->
                <div class="xian" v-if="item.lv == 2"></div>
                <!-- 二级线 -->
                <div
                  :class="{ xian2: true, xian2xx: item.lv == 4 }"
                  v-if="item.lv > 1"
                ></div>
                <!-- 二级竖线 -->
                <div
                  :class="{
                    xian2y: true,
                    xian2ys: index + 1 == template.length,
                  }"
                  v-if="panduan(item, index)"
                ></div>
                <div
                  :class="{
                    inputs: true,
                    Ts1: item.lv == 2 || item.name == '子章节',
                    Ts2: item.lv == 4,
                  }"
                  @mouseenter="item.ishuaru = 1"
                  @mouseleave="item.ishuaru = 0"
                >
                  <!-- 输入框 -->
                  <input
                    :class="{
                      inputed: item.ishuaru,
                      inputT: true,
                      inputTs: item.edit == 1,
                    }"
                    @focus="h1(item, 1)"
                    @blur="h1(item, 0)"
                    @change="Ichange(item)"
                    type="text"
                    v-model="item.title"
                  />
                  <div
                    @mouseenter="item.adds = 1"
                    @mouseleave="item.adds = 0"
                    :class="{ add: true }"
                  >
                    <!-- <div class="h1" v-show="item.adds"></div> -->
                    <!-- <img
                      v-show="item.adds"
                      src="https://yantoo-ailab-1320473685.cos.ap-shanghai.myqcloud.com/uploads/2-1添加-h.png"
                      @click="addItem(item, index)"
                      alt=""
                    /> -->
                    <!-- <div class="h2" v-show="item.adds"></div> -->
                  </div>
                  <!-- <el-tooltip
                    content="删除"
                    :show-after="1000"
                    placement="bottom"
                    effect="dark"
                    :hide-after="0"
                  >
                    <div
                      v-show="item.ishuaru"
                      :class="{ deletet: true }"
                      @mouseenter="item.isdeleted = 1"
                      @mouseleave="item.isdeleted = 0"
                      @click="deletet(index)"
                    >
                      <img
                        v-show="item.isdeleted == 0"
                        src="https://yantoo-ailab-1320473685.cos.ap-shanghai.myqcloud.com/uploads/2-1删除.png"
                        alt=""
                      />
                      <img
                        v-show="item.isdeleted == 1"
                        src="https://yantoo-ailab-1320473685.cos.ap-shanghai.myqcloud.com/uploads/2-1删除-h.png"
                        alt=""
                      />
                    </div>
                  </el-tooltip> -->
                </div>
              </li>
            </ul>
            <div class="xxbox" v-show="text == 0"></div>
          </div>
        </li>
      </ul>
      <div
        :class="{ anniu: true, annius: guodu.item3.anniu }"
        v-if="cPpt_s == -1"
      >
        <div class="shuaxin" @click="h2" v-if="cPpt.type == 'ai'">
          <img
            ref="shuaxinss"
            src="https://yantoo-ailab-1320473685.cos.ap-shanghai.myqcloud.com/uploads/shuaxin-xPPT.png"
            alt=""
          />
          <img
            style="position: absolute; left: 98px; top: 15px; width: 94px"
            src="https://yantoo-ailab-1320473685.cos.ap-shanghai.myqcloud.com/uploads/换个大纲@2x-ppt.png"
            alt=""
          />
        </div>
        <div class="next" @click="next" :class="{ inNext: text == 0 }">
          <img
            style="width: 11px; height: 11px"
            src="https://yantoo-ailab-1320473685.cos.ap-shanghai.myqcloud.com/uploads/下一页-h@2x-PPT.png"
            alt=""
          />
          <img
            style="position: absolute; left: 263px; top: 16px; width: 71px"
            src="https://yantoo-ailab-1320473685.cos.ap-shanghai.myqcloud.com/uploads/下一步@2x-PPT.png"
            alt=""
          />
        </div>
      </div>
    </div>
    <div class="mindmap">
      <el-dialog
        top="260px"
        title=""
        v-model="mindmap"
        style="height: 264px; width: 540px"
      >
        <!-- <siweidaotu></siweidaotu> -->
      </el-dialog>
    </div>
    <!-- 撑高度的盒子 -->
    <div v-if="cPpt_s > 3" class="" style="height: 380px"></div>
  </div>
</template>

<script setup>
import { ref, computed, onMounted, onUnmounted } from "vue";
import { useRouter } from "vue-router";
import { ElMessage } from "element-plus";
import { chatAPIProcess, getPptTypeList } from "@/api/index";
import { marked } from "marked";
// import siweidaotu from '@/components/siweidaotu.vue'
let router = useRouter(); //路由
let token = ref(localStorage.getItem("token")); //网站token
let input = ref("");
let md = ref("");
let item_imged = ref(-1); //item 滑动事件的选中
let isimged = ref(-1); //类型的选中
let item1_imged = ref(-1); //item1 滑动事件的选中
let shuaxinss = ref(); //img的元素本身
let text = ref(1); //生成大纲状态
let intext = ref(0); //生成大纲前
let texted = ref(0); //大纲生成完成的状态
let leixings = ref([]); //模板类型
let str999 = ref(
  `#  PPT标题  
## 章节标题
### 内容页标题
#### 内容页子标题
## 章节标题
### 内容页标题
#### 内容页子标题
##* 结束语`
); //markdown模板提示
let strMarkdown = ref("");
let mindmap = ref(false); //思维导图
let cPpt_s = ref(1); //进行的状态
let status1 = 360; //刷新旋转的旋转圈速，达到点一次加360
let template = ref([]); //大纲的对象形式
let textarr = ref([
  "Hi~微信用户，我是您的Al智能助理小A,请问您想通过什么方式来生成PPT呢？", //textarr
  "选择您需要的类型",
  " 好的，在下方输入您想要的PPT主题，就可以开始智能生成啦~",
  "为你生成如下大纲",
]);
let textObj = ref({
  //对话的文字
  item: "",
  item1: "",
  item2: "",
  item3: "",
});
let guodu = ref({
  //过渡状态
  item: {
    ais: 0,
    xuanze: 0,
    intext: 0,
  },
  item1: {
    ais: 0,
    xuanze: 0,
    intext: 0,
  },
  item2: {
    ais: 0,
    bbox: 0,
    intext: 0,
  },
  item3: {
    ais: 0,
    bbox: 0,
    intext: 0,
    anniu: 0,
  },
});
let cPpt = ref({
  //创建PPT所需的参数
  name: "", //名称
  ppt_demo_id: "", //模板
  attr_json: "", //目标风格等参数的json字符串
  type: "", //选择的生成方式 ai->ai生成，demo->模板生成,import->导入
  outline: "", //大纲
});
function nextMd() {
  jiexiMd(strMarkdown.value);
  cPpt_s.value = -1;
  guodu.value.item3.ais = 1;
  intext.value = 1;
  setTimeout(() => {
    let arr = textarr.value[3].split("");
    let i = 0;
    let a = setInterval(() => {
      textObj.value.item3 += arr[i++];
      if (i == arr.length) {
        clearInterval(a);
        setTimeout(() => {
          guodu.value.item3.bbox = 1;
          setTimeout(() => {
            guodu.value.item3.anniu = 1;
          }, 200);
        }, 200);
      }
    }, 20);
  }, 50);
}
function Ichange(item) {
  //使目录与章节绑定，让它们的值一样
  console.log(item);
  if (item.isIndex) {
    template.value.forEach((value) => {
      if (value.isIndex == item.isIndex) {
        value.title = item.title;
      }
    });
  }
}
function next() {
  //点击下一步执行，保存PPT所需的数据到本地
  if (text.value == 1) {
    // let obj = {}
    // obj.topic = template.value[0].title
    // obj.catalogs = [template.value[2].title, ...template.value.filter(value => value.lv == 2).map(value => value.title)]
    // obj.contents = []
    // let arr = template.value.slice(obj.catalogs.length,)
    // arr.forEach((value, index) => {
    //   if (value.lv == 1 && index != arr.length - 1) {
    //     obj.contents.push({ title: value.title, desc: arr[index + 1].title })
    //   }
    // })
    // cPpt.value.outline = JSON.stringify(obj)
    let str = "";
    let is4 = template.value.find((value) => value.lv == 4);
    template.value.forEach((value, index) => {
      if (is4) {
        switch (value.lv) {
          case 1:
            if (index == 0) {
              str += "# " + value.title + "\n";
            } else if (index == 1) {
            } else {
              if (value.name == "结尾") {
                str += "##* " + value.title + "\n";
              } else {
                str += "## " + value.title + "\n";
              }
            }
            break;
          case 3:
            str += "### " + value.title + "\n";
            break;
          case 4:
            str += "#### " + value.title + "\n";
            break;
          default:
            break;
        }
      }
    });
    cPpt.value.outline = str;
    cPpt.value.ppt_demo_id = cPpt.value.ppt_demo_id.name;
    cPpt.value.name = template.value[0].title;
    console.log(cPpt.value);
    localStorage.setItem("pptCanshu", JSON.stringify(cPpt.value));
    router.push("/templ");
  }
}

onMounted(async () => {
  function autoExpand(textarea) {
    if (textarea.classList[0] !== "shuru") {
      textarea.style.height = "auto";
      textarea.style.height = textarea.scrollHeight + "px";
      if (textarea.value.length < 48) {
        console.log(11);
        textarea.style.height = 23 + "px";
      }
      if (textarea.classList[1] == "textareaed") {
        textarea.style.height = "auto";
      }
    }
  }
  document.addEventListener(
    "input",
    function (e) {
      if (e.target.tagName.toLowerCase() === "textarea") {
        autoExpand(e.target);
      }
    },
    false
  );
  setTimeout(async () => {
    guodu.value.item.ais = 1;
    let arr = textarr.value[0].split("");
    let i = 0;
    let a = setInterval(() => {
      textObj.value.item += arr[i++];
      if (i == arr.length) {
        clearInterval(a);
        setTimeout(() => {
          guodu.value.item.xuanze = 1;
        }, 100);
      }
    }, 20);
  }, 100);
});
function gundong(i) {
  //点击滚动条滚动到对应的高度
  console.log(i);
  switch (i) {
    case 1:
      document
        .querySelectorAll(".home")[0]
        .scrollTo({ top: 432, left: 0, behavior: "smooth" });
      break;
    case 2:
      document
        .querySelectorAll(".home")[0]
        .scrollTo({ top: 432 + 312, left: 0, behavior: "smooth" });
      break;
    case 3:
      document
        .querySelectorAll(".home")[0]
        .scrollTo({ top: 432 + 312 + 457, left: 0, behavior: "smooth" });
      break;
    default:
      document.querySelectorAll(".home")[0].scrollTo({
        top: document.querySelectorAll(".home")[0].scrollHeight,
        left: 0,
        behavior: "smooth",
      });
      break;
  }
}
async function leixingArr() {
  //PPT内容类型的图片，以及所需参数的获取
  let data2 = await getPptTypeList();
  if (cPpt.value.type == "ai") {
    leixings.value = data2.data;
    leixings.value[0].image = [
      "https://yantoo-ailab-1320473685.cos.ap-shanghai.myqcloud.com/uploads/工作汇报-xPPT.png",
      "https://yantoo-ailab-1320473685.cos.ap-shanghai.myqcloud.com/uploads/工作汇报h-xPPT.png",
    ];
    leixings.value[1].image = [
      "https://yantoo-ailab-1320473685.cos.ap-shanghai.myqcloud.com/uploads/商业计划-xPPT.png",
      "https://yantoo-ailab-1320473685.cos.ap-shanghai.myqcloud.com/uploads/商业计划h-xPPT.png",
    ];
    leixings.value[2].image = [
      "https://yantoo-ailab-1320473685.cos.ap-shanghai.myqcloud.com/uploads/项目标书-xPPT.png",
      "https://yantoo-ailab-1320473685.cos.ap-shanghai.myqcloud.com/uploads/项目标书h-xPPT.png",
    ];
    leixings.value[3].image = [
      "https://yantoo-ailab-1320473685.cos.ap-shanghai.myqcloud.com/uploads/广告策划-xPPT.png",
      "https://yantoo-ailab-1320473685.cos.ap-shanghai.myqcloud.com/uploads/广告策划h-xPPT.png",
    ];
    leixings.value.forEach((value) => {
      value.key_word_map.forEach((v2) => {
        if (v2.value) {
          let arr = [];
          v2.value
            .split("，")
            .forEach((v3, i3) =>
              arr.push({ name: v3, index: i3, ised: 0, obj: v2 })
            );
          v2.arr = arr;
        }
      });
    });
  }
}
async function sc1(t) {
  //第一步的点击
  if (cPpt_s.value == 1) {
    cPpt_s.value += 1;
    if (t) {
      leixingArr();
    }
  }

  if (cPpt.value.type == "ai" && t) {
  } else if (cPpt.value.type == "demo" && !t) {
  } else {
    cPpt_s.value = 1;
    t ? (cPpt.value.type = "ai") : (cPpt.value.type = "demo");
    if (t) {
      leixingArr();
    } else {
      // setTimeout(() => {
      //   leixings.value = [
      //     {
      //       image: "https://yantoo-ailab-1320473685.cos.ap-shanghai.myqcloud.com/uploads/pptDOC.png",
      //       name: "Mind Map",
      //     },
      //     {
      //       image: "https://yantoo-ailab-1320473685.cos.ap-shanghai.myqcloud.com/uploads/pptTXT.png",
      //       name: "Markdown",
      //     }
      //   ]
      // }, 500)
    }
    guodu.value.item1.ais = 0;
    guodu.value.item1.xuanze = 0;
    guodu.value.item2.ais = 0;
    guodu.value.item2.bbox = 0;
    guodu.value.item3.ais = 0;
    guodu.value.item3.bbox = 0;
    textObj.value.item1 = "";
    textObj.value.item2 = "";
    textObj.value.item3 = "";
    isimged.value = -1;
    setTimeout(() => {
      cPpt_s.value = 2;
    }, 100);
  }
  setTimeout(() => {
    gundong(1);
  }, 100);

  if (guodu.value.item1.ais == 0) {
    setTimeout(() => {
      guodu.value.item1.ais = 1;
      if (cPpt.value.type == "demo") {
        textarr.value[1] = "选择文件类型上传";
      } else {
        textarr.value[1] = "选择您需要的类型";
      }
      let arr = textarr.value[1].split("");
      let i = 0;
      let str = "";
      let a = setInterval(() => {
        str += arr[i++];
        textObj.value.item1 = str;
        if (i == arr.length) {
          clearInterval(a);
          setTimeout(() => {
            guodu.value.item1.xuanze = 1;
          }, 200);
        }
      }, 20);
    }, 500);
  }
}
function sc2(i) {
  //第二步的点击
  if (cPpt_s.value == 2) {
    cPpt_s.value += 1;
  }
  isimged.value = i;
  cPpt.value.ppt_demo_id = leixings.value[i];
  console.log(cPpt.value);
  setTimeout(() => {
    gundong(2);
  }, 100);
  if (guodu.value.item2.ais == 0) {
    setTimeout(async () => {
      guodu.value.item2.ais = 1;
      let arr = textarr.value[2].split("");
      let i = 0;
      if (!textObj.value.item2) {
        let a = setInterval(() => {
          textObj.value.item2 += arr[i++];
          if (i == arr.length) {
            clearInterval(a);
            setTimeout(() => {
              guodu.value.item2.bbox = 1;
            }, 200);
          }
        }, 20);
      }
    }, 500);
  }
}
function sc3(item) {
  //风格的点击
  item.obj.index = item.index;
  item.obj.arr.forEach((value, index) => {
    if (value.index == value.obj.index) {
      value.ised = 1;
    } else {
      value.ised = 0;
    }
  });
}
async function sc4(a) {
  //点击生成大纲的点击事件
  if (a) {
    if (cPpt_s.value == 3) {
      cPpt_s.value = -1;
    }
    setTimeout(() => {
      gundong(3);
    }, 100);
    if (guodu.value.item3.ais == 0) {
      guodu.value.item3.ais = 1;
    }
    if (text.value) {
      AIdagang();
    }
    text.value = 0;
  } else {
    ElMessage.error("标题不能为空");
  }
}
async function AIdagang() {
  //AI大纲的请求渲染
  //定义回调函数
  let daduan = false;
  const onDownloadProgress = async (progressEvent) => {
    //打断
    if (daduan) {
      return;
    }
    if (cPpt_s.value !== -1) {
      daduan = true;
    }
    if (intext.value == 0) {
      intext.value = 1;
      setTimeout(() => {
        let arr = textarr.value[3].split("");
        let i = 0;
        let a = setInterval(() => {
          textObj.value.item3 += arr[i++];
          if (i == arr.length) {
            clearInterval(a);
            setTimeout(() => {
              guodu.value.item3.bbox = 1;
              setTimeout(() => {
                guodu.value.item3.anniu = 1;
              }, 200);
            }, 200);
          }
        }, 20);
      }, 50);
    }
    const xhr = progressEvent.event.target;
    let { responseText } = xhr;
    console.log(responseText, 111);
    if (responseText.indexOf("#####") != -1) {
      if (daduan) {
        return;
      }
      daduan = true;
      AIdagang();
      return;
    }
    if (document.querySelectorAll(".xxbox")[1]) {
      document.querySelectorAll(".xxbox")[1].innerHTML = marked(responseText);
      document.querySelectorAll(".xxbox")[1].scrollTo({
        top: document.querySelectorAll(".xxbox")[1].scrollHeight,
        left: 0,
        behavior: "smooth",
      });
    }
  };
  console.log(cPpt.value);
  let arr = cPpt.value.ppt_demo_id.key_word_map.map((value) => {
    if (value.arr) {
      let str = value.index ? value.arr[value.index].name : "";
      return {
        name: value.name,
        value: str,
      };
    } else {
      return value;
    }
  });
  let str = JSON.stringify(arr);
  cPpt.value.attr_json = str;
  let strmd = await chatAPIProcess(
    {
      title: cPpt.value.name,
      name: cPpt.value.ppt_demo_id.name,
      attr_json: str,
    },
    onDownloadProgress
  );
  if (daduan) {
    return;
  }
  jiexiMd(strmd);
  text.value = 1;
}
function jiexiMd(str) {
  //解析md
  console.log(str);
  md.value = str;
  let dataArr = str.split("\n");
  console.log(dataArr);
  let mulu = [
    {
      lv: 1,
      name: "目录",
      title: "目录",
      adds: 0, //添加的滑入事件
      edit: 0, //获得焦点的事件
      isdeleted: 0, //删除的选中
      ishuaru: 0, //盒子的滑入事件
    },
  ];

  let array = dataArr
    .map((value, index) => {
      if (value) {
        let obj;
        let a = dataArr.find((value) => value.slice(0, 3) == "###");
        let str = value.slice(0, 2);
        switch (str) {
          case "##":
            if (value.slice(0, 3) == "###") {
              if (value.slice(0, 4) == "####") {
                obj = {
                  lv: 4,
                  name: "内页",
                  title: value.slice(4),
                  adds: 0, //添加的滑入事件
                  edit: 0, //获得焦点的事件
                  isdeleted: 0, //删除的选中
                  ishuaru: 0, //盒子的滑入事件
                };
              } else {
                obj = {
                  lv: 3,
                  name: "内页",
                  title: value.slice(3),
                  adds: 0, //添加的滑入事件
                  edit: 0, //获得焦点的事件
                  isdeleted: 0, //删除的选中
                  ishuaru: 0, //盒子的滑入事件
                };
              }
            } else if (value.slice(0, 3) == "##*") {
              obj = {
                lv: 1,
                name: "结尾",
                isIndex: index, //用来双向绑定目录的标记
                title: value.slice(3),
                adds: 0, //添加的滑入事件
                edit: 0, //获得焦点的事件
                isdeleted: 0, //删除的选中
                ishuaru: 0, //盒子的滑入事件
              };
            } else {
              obj = {
                lv: 1,
                name: "章节",
                isIndex: index, //用来双向绑定目录的标记
                title: value.slice(2),
                adds: 0, //添加的滑入事件
                edit: 0, //获得焦点的事件
                isdeleted: 0, //删除的选中
                ishuaru: 0, //盒子的滑入事件
              };
            }
            break;
          default:
            break;
        }
        return obj;
      }
    })
    .filter((value) => value);
  dataArr.forEach((value, index) => {
    value.slice(0, 3) == "## "
      ? mulu.push({
          lv: 2,
          name: "目录",
          isIndex: index, //用来双向绑定目录的标记
          title: value.slice(3),
          adds: 0, //添加的滑入事件
          edit: 0, //获得焦点的事件
          isdeleted: 0, //删除的选中
          ishuaru: 0, //盒子的滑入事件
        })
      : "";
  });
  template.value = [
    {
      lv: 1,
      name: "主题",
      title: dataArr[0].slice(1),
      adds: 0, //添加的滑入事件
      edit: 0, //获得焦点的事件
      isdeleted: 0, //删除的选中
      ishuaru: 0, //盒子的滑入事件
    },
    ...mulu,
    ...array,
  ];
  console.log(template.value);
}
function h1(e, i) {
  e.edit = i;
}
function h2() {
  //图片旋转的函数
  shuaxinss.value.style.transform = `rotate(${status1}deg)`;
  status1 += 360;
  if (text.value) {
    AIdagang();
  }
  texted.value = 0;
  text.value = 0;
}
//添加
function addItem(item, index) {
  let obj = JSON.parse(JSON.stringify(item));
  obj.title = "";
  obj.edit = 1;
  console.log(obj);
  template.value.splice(index + 1, 0, obj);
  setTimeout(() => {
    try {
      document.querySelectorAll(".inputT")[index + 1].focus();
    } catch (e) {}
  }, 500); // 设置延时0.2秒
}
//计算主线总长
const zlong = computed(() => {
  let long = "height: " + (template.value.length * 33 - 28) + "px;";
  return long;
});
function deletet(i) {
  if (template.value.length == 1) {
  } else {
    template.value.splice(i, 1);
  }
}
function panduan(item, index) {
  let a =
    item.lv > 2 &&
    (index + 1 == template.value.length || template.value[index + 1].lv != 1);
  if (index + 1 == template.value.length) {
    a = 0;
  }
  return a;
}
function fanhui() {
  //返回的事件
  if (cPpt_s.value == -1) {
    cPpt_s.value = 3;
    text.value = 1;
    intext.value = 0;
    guodu.value.item3.bbox = 0;
    guodu.value.item3.ais = 0;
    guodu.value.item3.intext = 0;
    textObj.value.item3 = "";
    guodu.value.item3.anniu = 0;
  } else {
    router.back();
  }
}
onUnmounted(() => {});
</script>

<style lang="less" scoped>
.home {
  position: relative;
  font-size: 16px;
  width: 100%;
  height: 100vh;
  scroll-behavior: smooth;
  transition: 0.5s;
  overflow: auto;
  background-color: #f0f3fa;
  background-image: url("https://yantoo-ailab-1320473685.cos.ap-shanghai.myqcloud.com/uploads/气泡bg-xPPT.png");

  .fanhui {
    position: absolute;
    top: 60px;
    left: 113px;
    width: 30px;
    height: 30px;
    min-width: 18px;
    min-height: 18px;
    z-index: 1;
  }

  .neirong {
    position: relative;
    width: 1200px;
    top: 3.75rem;
    left: 50%;
    transform: translateX(-50%);
    padding: 1px;

    .list {
      width: 100%;

      li {
        margin: 40px 0;
      }

      .ais {
        margin: 50px 0;
      }

      li {
        .xuanze,
        .ais,
        .bbox {
          opacity: 0;
        }

        .yuan1 {
          transform: translateX(20px);
          transition: 1s;
          opacity: 1;
        }
      }

      .item {
        width: 100%;
        margin-bottom: 40px;

        .ais {
          position: relative;
          display: flex;

          .text {
            font-size: 17.6px;
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            padding: 16px;
            background-color: #c1d4fb;
            border-radius: 16px 16px 16px 0;
            height: 28px;
            margin-left: 7px;
            position: relative;
          }
        }

        .xuanze {
          margin-top: 40px;
          width: 100%;
          height: 176px;
          display: flex;
          justify-content: center;
          position: relative;
          cursor: pointer;

          .img {
            position: relative;
            border-radius: 16px;
            margin: 0 30px;
            transition: 0.5s;

            .imgs {
              width: 110px;
              height: 110px;
              border-radius: 16px;
              margin: 10px;
              background-color: #ffffff;

              img {
                width: 90px;
                height: 90px;
                margin: 10px;
              }
            }

            .button {
              text-align: center;
              width: 112px;
              height: 36px;
              border-radius: 8px;
              position: absolute;
              top: 130px;
              left: 10px;
              cursor: pointer;
              transition: 0.5s;

              p {
                font-size: 12.8px;
                line-height: 36px;
                color: #ffffff;
                font-weight: 700;
              }
            }

            .button1 {
              background-color: #8b94e6;

              // :hover {
              //   color: #7fa3ee;
              // }
            }

            .button2 {
              background-color: #7fa3ee;

              // :hover {
              //   color: #8b94e6;
              // }
            }

            &:active {
              // .button {
              transform: scale(0.8);
              // }
            }
          }

          .img1 {
            background-color: #c8cff6;
          }

          .img2 {
            background-color: #cadbff;
          }

          .imged1 {
            transform: scale(1.1);
            box-shadow: 2px 2px 8px #0148c33f, -1px -1px 8px #0148c33f;
            background-color: #cadbff;
          }

          .imged2 {
            transform: scale(1.1);
            box-shadow: 2px 2px 8px #0148c33f, -1px -1px 8px #0148c33f;
            background-color: #c8cff6;
          }
        }
      }

      .item1 {
        width: 100%;
        margin-top: 44px;

        .ais {
          position: relative;
          display: flex;

          .text {
            font-size: 17.6px;
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            border-radius: 16px 16px 16px 0;
            background-color: #c1d4fb;
            height: 28px;
            margin-left: 7px;
            text-align: center;
            position: relative;
            padding: 16px;
          }
        }

        .xuanze {
          margin-top: 40px;
          display: flex;
          justify-content: center;
          position: relative;

          .leixingAi {
            width: 120px;
            height: 152px;
            margin: 0 40px;
            text-align: center;
            cursor: pointer;
            position: relative;

            .kuang {
              width: 120px;
              height: 120px;
              position: absolute;
              background-color: #6e8fff;
              z-index: -1;
              border-radius: 16px;
              transition: 0.5s;
            }

            .dian {
              width: 48px;
              height: 48px;
              border-radius: 18px;
              position: absolute;
              top: 36px;
              left: 36px;
              transition: 1s;
              background-color: #6e8fff;
              z-index: -1;
              filter: blur(10px);
            }

            .dian2 {
              width: 36px;
              height: 36px;
              border-radius: 12px;
              position: absolute;
              top: 42px;
              left: 42px;
              transition: 1.25s;
              background-color: #6e8fff;
              z-index: -1;
              filter: blur(8px);
            }

            .dian3 {
              width: 24px;
              height: 24px;
              border-radius: 6px;
              position: absolute;
              top: 48px;
              left: 48px;
              transition: 1.4s;
              background-color: #6e8fff;
              z-index: -1;
              filter: blur(6px);
            }

            .img {
              border-radius: 16px;
              width: 120px;
              height: 120px;
              box-shadow: 2px 2px 8px #0148c33f, -1px -1px 8px #0148c33f;
              box-sizing: border-box;
              position: relative;
              background-color: #fff;
              transition: 0.5s;

              img {
                width: 70px;
                height: 72px;
                position: absolute;
                top: calc(50% - 36px);
                left: calc(50% - 35px);
                transition: 0.5s;
              }
            }

            .imged {
              box-shadow: none;
              // border: 3px solid #6E8FFF;
              transform: scale(0.9);
            }

            p {
              margin-top: 12px;
            }
          }

          .box {
            width: 800px;
            height: 400px;
            padding: 36px;
            background-color: #fff;
            border-radius: 16px;

            .text-tishi {
              margin: 0 0 16px 0;
              font-size: 18px;
            }

            .shuru {
              resize: none;
              margin: 16px 0 0 0;
              width: 100%;
              height: 80%;
              color: #000;
              border: 0;
              outline: 0;
              font-family: normal;
            }
          }
        }

        .xiayibu-box {
          margin: 36px 0 0;
          display: flex;
          justify-content: center;
          width: 100%;
          position: absolute;
          top: 100%;
          padding: 0 0 36px 0;
          .xiayibu {
            width: 610px;
            height: 50px;
            background-color: #6e8fff;
            position: relative;
            border-radius: 25px;
          }
        }
      }

      .item2 {
        width: 100%;

        .ais {
          position: relative;
          display: flex;

          .text {
            font-size: 17.6px;
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            padding: 16px;
            border-radius: 16px 16px 16px 0;
            background-color: #c1d4fb;
            height: 28px;
            margin-left: 7px;
            position: relative;
          }
        }

        .bbox {
          margin: 40px;
          width: 880px;
          position: relative;
          left: 136px;
          box-shadow: 1px 1px 4px #0148c34f;
          border-radius: 30px;
          top: 0px;
          background-color: #ffffff;
          padding-bottom: 30px;

          .inputss {
            border-radius: 16px;
            border: 1px solid #7fa3ee;
            padding-left: 16px;

            &:focus {
              outline: 2px solid #7fa3ee;
            }
          }

          .input {
            margin-left: 25.6px;
            padding: 30px;
            height: 60px;
            display: flex;
            position: relative;
            padding-bottom: 12px;
            font-size: 16px;
          }

          .input2 {
            padding: 16px 16px 12px 18px;
            display: flex;
            position: relative;
            font-size: 14px;

            span {
              width: 108px;
              height: 51px;
              line-height: 51px;
              text-align: right;
            }
            .textarea-box {
              width: 700px;
              padding: 14px;
              border-radius: 16px;
              background-color: #f7f7f7;
              flex-shrink: 0;
              flex-grow: 0;
            }

            textarea {
              width: 700px;
              border: 0;
              outline: none;
              background-color: #f7f7f7;
              padding: 0;
              font-size: 15px;
              resize: none;
              /* 禁止用户手动调整textarea大小 */
              /* 设置 textarea 宽度为父元素的100% */
              height: 23px;
              line-height: 23px;
            }
            .textareaed {
              height: auto;
            }
            .sp {
              display: flex;
              width: 740px;

              p {
                width: 102px;
                background-color: #ffffff;
                height: 40px;
                border-radius: 20px;
                margin: 0 16px;
                text-align: center;
                line-height: 40px;
                border: 1px solid rgb(201, 201, 201);
                cursor: pointer;
              }

              .p1 {
                background-color: #7fa3ee;
                color: #ffffff;
                border-color: #ffffff;
              }
            }

            img {
              width: 60px;
              height: 60px;
              position: absolute;
              left: calc(100% - 96px);
              z-index: 999;
            }
          }

          .zuihou {
            padding-bottom: 36px;
          }

          .p {
            position: relative;
            font-size: 14px;
            left: 125px;
            color: #4c4c4c;
          }
        }
      }

      .item3 {
        width: 100%;
        position: relative;

        .ais {
          position: relative;
          display: flex;

          .text {
            font-size: 17.6px;
            border-radius: 16px 16px 16px 0;
            background-color: #c1d4fb;
            height: 28px;
            padding: 16px;
            margin-left: 7px;
            text-align: center;
            position: relative;
          }

          div.text {
            --speed-of-animation: 0.9s;
            --gap: 6px;
            display: flex;
            justify-content: space-between;

            .qipao {
              width: 14px;
              height: 14px;
              margin: 6px;
              position: relative;
              top: 50%;
              transform: translateY(-50%);
              animation: scale var(--speed-of-animation) ease-in-out infinite;
            }

            img:nth-child(2) {
              width: 16px;
              height: 16px;
              animation-delay: -0.8s;
            }

            img:nth-child(3) {
              width: 18px;
              height: 18px;
              animation-delay: -0.6s;
            }

            @keyframes scale {
              30% {
                width: 14px;
                height: 14px;
              }

              60% {
                width: 16px;
                height: 16px;
              }

              100% {
                width: 18px;
                height: 18px;
              }
            }
          }
        }

        .bbox {
          margin: 40px;
          width: 820px;
          height: 391px;
          padding: 30px;
          position: relative;
          left: 136px;
          box-shadow: 1px 1px 4px rgb(155, 155, 155);
          border-radius: 30px;
          top: 0px;
          background-color: #ffffff;

          .box1,
          .box2 {
            width: 26px;
            height: 30px;
            padding: 11px 13px;
            background-color: #ffffff;
            border-radius: 8px;
            cursor: pointer;

            img {
              width: 26px;
              height: 30px;
            }
          }

          .box1 {
            position: absolute;
            left: 900px;
            top: 0;
          }

          .box2 {
            position: absolute;
            left: 900px;
            top: 72px;
          }

          .wenhao {
            position: absolute;
            left: 800px;
            width: 18px;
            height: 18px;
            top: 0;
          }

          hr {
            margin: 19px 0 0;
          }

          .xxbox {
            position: relative;
            border-bottom: 1px solid #d4e0f3;
            overflow: auto;
            max-height: 334px;

            .zxian {
              width: 1px;
              height: 312px;
              background-color: #d4e0f3;
              position: absolute;
              top: 19.2px;
              left: 284px;
            }

            li {
              height: 23px;
              margin: 10px;
              text-align: center;
              position: relative;

              p {
                width: 54px;
                height: 23px;
                font-size: 12px;
                position: relative;
                left: 207px;
                background-color: #e1e9fb;
                border: 1px solid #7fa3ee;
                line-height: 24px;
                border-radius: 4px;
                color: #0148c3;
              }

              .dian {
                width: 7px;
                height: 7px;
                background-color: #d4e0f3;
                position: absolute;
                top: 8px;
                left: 271px;
                border-radius: 50%;
              }

              .dian2 {
                width: 5px;
                height: 5px;
                border-radius: 50%;
                border: 1px solid #d4e0f3;
                background-color: #ffffff;
                position: absolute;
                top: 8px;
                left: 292.8px;
                z-index: 99;
              }

              // 次级位置
              .dian2x1 {
                left: 286.4px;
              }

              // 次次级位置
              .dian2x2 {
                left: 302.4px;
              }

              .xian {
                width: 24.8px;
                height: 1px;
                background-color: #d4e0f3;
                position: absolute;
                left: 274.08px;
                top: 11px;
              }

              .xian2 {
                width: 16px;
                height: 1px;
                position: absolute;
                left: 274.08px;
                top: 11px;
                background-color: #d4e0f3;
              }

              //次次级位置
              .xian2xx {
                left: 289.6px;
              }

              .xian2y {
                width: 1px;
                height: 33.6px;
                background-color: #d4e0f3;
                position: absolute;
                left: 289.6px;
                top: 11px;
              }

              .xian2ys {
                height: 32px;
              }

              .inputs {
                position: absolute;
                width: 520px;
                height: 43px;
                left: 300px;
                top: 0;

                .inputT {
                  display: block;
                  position: absolute;
                  width: 240px;
                  padding: 5px 12px;
                  height: 20px;
                  border-radius: 4px;
                  top: -6px;
                  left: 0px;
                  font-size: 16px;
                  border: 0;
                  outline: none;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  white-space: nowrap;
                }

                .deletet {
                  position: absolute;
                  width: 18px;
                  height: 18px;
                  left: 300px;
                  top: 3px;
                  transform: translateY(0);
                  cursor: pointer;

                  img {
                    width: 18px;
                    height: 18px;
                    position: absolute;
                    top: 0;
                    left: 0;
                  }
                }

                .add {
                  width: 264px;
                  height: 24px;
                  position: absolute;
                  top: 12px;
                  left: 0px;
                  z-index: 999;

                  img {
                    width: 18px;
                    height: 18px;
                    cursor: pointer;
                    position: absolute;
                    top: calc(50% - 9px);
                    left: calc(50% - 9px);
                  }
                }

                .inputTs {
                  outline: 1px solid #7fa3ee;
                }

                .inputed {
                  border: 1px solid #b7dbff;
                }
              }

              .Ts1 {
                left: 324px;
              }

              .Ts2 {
                left: 336px;
              }
            }
          }
        }
      }
    }

    .anniu {
      display: flex;
      position: relative;
      opacity: 0;
      transition: 0.5s;
      justify-content: center;
      .shuaxin {
        width: 260px;
        height: 50px;
        margin: 0 20px;
        position: relative;
        border-radius: 25px;
        background-color: #ffffff;
        border: 1px solid #7fa3ee;
        cursor: pointer;
        img {
          width: 20px;
          height: 20px;
          position: absolute;
          top: 13px;
          left: 68px;
          transition: 1s;
        }

        span {
          line-height: 60px;
          position: relative;
          left: 102px;
        }
      }

      .next {
        width: 610px;
        height: 50px;
        position: relative;
        border-radius: 50px;
        background-color: #6e8fff;
        cursor: pointer;
        transition: 0.5s;

        img {
          width: 16px;
          height: 16px;
          position: absolute;
          top: 20px;
          left: 347px;
        }

        span {
          line-height: 60px;
          position: relative;
          left: 268px;
          color: #ffffff;
        }
      }
      .inNext {
        cursor: not-allowed;
        background-color: #7fa2ee46;
      }
    }

    .annius {
      opacity: 1;
      transform: translateX(12px);
    }
  }
}
</style>
