import { createApp } from 'vue'
import App from './App.vue'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import router from './router'
import store from './store'
import axios from './api'

// 如果您正在使用CDN引入，请删除下面一行。
// import * as ElementPlusIconsVue from '@element-plus/icons-vue'


// 引入图标库链接
import './on-qy/iconfont.css'

const app = createApp(App)
// 将 Axios 实例注册到 Vue 实例中
// app.config.globalProperties.$axios = axios
app.provide('$axios', axios)

createApp(App).use(store).use(router).use(ElementPlus).mount('#app')
