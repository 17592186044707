<template>
  <div id="app">
    <router-view />
  </div>
  <!-- <router-link to="/">Home</router-link> -->
  <!-- <router-link to="/about">About</router-link> -->
  <!-- <div></div> -->
</template>

<script>
export default {
  name: "App",
  components: {},
  mounted() {
    // // 设置根元素的字体大小，可以根据需要进行调整
    // this.setRootFontSize();
    // // 监听窗口大小变化，动态调整字体大小
    // window.addEventListener("resize", this.setRootFontSize);
  },
  methods: {
    setRootFontSize() {
      // 获取用户的浏览器字体大小
      var userBrowserFontSize = window.getComputedStyle(
        document.documentElement
      ).fontSize;
      // console.log(userBrowserFontSize);
      var zoomLevel = window.devicePixelRatio;
      // console.log(1,zoomLevel);
      // 根据视口宽度设置字体大小，可以根据需要进行调整
      const baseSize = (window.innerWidth / 1920) * 16 * zoomLevel; // 1rem等于视口宽度的1/10
      document.documentElement.style.fontSize = `${baseSize}px`;
    },
  },
};
</script>
<style lang="less">
@font-face {
  font-family: 'AlibabaPuHuiTi_2_55_Regular';
  src:url('./assets/fdeef59021e2cf802bac557ae2c21cb6.otf');
}

*::-webkit-scrollbar {
  display: none;
}

* {
  padding: 0;
  margin: 0;
  list-style: none;
  font-family: 'AlibabaPuHuiTi_2_55_Regular';
}
#app{
  width: 100vw;
  height: 100vh;
}



</style>
